<template>
  <b-form-group label="Костюм*" :inline="true">
    <div class="d-flex select-wrapper">
      <b-input-group-text>
        <i class="icon-mustache"></i>
      </b-input-group-text>
      <multiselect
        v-model="selectedSuit"
        :options="suits"
        placeholder="Выберите костюм"
        :multiple="false"
        :showNoResults="false"
        :allow-empty="false"
        :showLabels="false"
        label="title"
        return="id"
        track-by="id"
      ></multiselect>
    </div>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Suit",
  components: { Multiselect },
  props: ["suits"],
  data() {
    return {
      selectedSuit: {}
    };
  },
  watch: {
    selectedSuit(newValue) {
      this.$emit("onValueChange", "suit_id", newValue.id);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":"Контактный телефон*","inline":true}},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-phone"})])]),_c('masked-input',{staticClass:"form-control",class:{ 'is-invalid': _vm.$v.phone.$error && _vm.isTouched.phone },attrs:{"type":"tel","name":"phone","placeholder":"+38 (999) 999-9999","mask":[
        '+',
        '3',
        '8',
        ' ',
        '(',
        /[0-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ],"guide":true,"placeholderChar":"#","autocomplete":"off"},on:{"input":function($event){return _vm.setTelephoneNumber()}},model:{value:(_vm.$v.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.phone.$model"}})],1),_c('small',{staticClass:"form-text text-muted"},[_vm._v("напр. +38 (999) 999-9999")]),(_vm.$v.phone.$error && _vm.isTouched.phone)?_c('div',{staticClass:"invalid-error"},[(!_vm.$v.phone.required)?_c('span',[_vm._v("Это обязательное для заполнения поле.")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <h1 class="mb-4">Регистрация на игру закрыта</h1>
    <h4>Всем спасибо, все свободны :-)</h4>
  </section>
</template>

<script>
export default {
  name: "RegistrationForbidden"
};
</script>

<style scoped>
section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 16px;
}

h1 {
  font-size: 3em;
}
</style>

<template>
  <b-form-group label="Пароль*" :label-cols="3" :inline="true">
    <b-input-group>
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-key"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        type="password"
        class="form-control"
        placeholder="Придумайте пароль"
        v-model.trim="$v.password.$model"
        v-on:input="setPassword()"
        :class="{ 'is-invalid': $v.password.$error }"
      ></b-form-input>
    </b-input-group>
    <div class="invalid-error" v-if="$v.password.$error">
      <span>
        Пароль слишком слабый. Он должен соответствовать следующим требованиям:
        иметь (как минимум) одну заглавную латинскую букву, одну цифру и
        состоять из 6 символов.
      </span>
    </div>
    <b-input-group>
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-key"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        type="password"
        class="form-control"
        placeholder="Подтвердите пароль"
        v-model.trim="$v.password_confirm.$model"
        v-on:input="setConfirmedPassword()"
        :class="{ 'is-invalid': $v.password_confirm.$error }"
      ></b-form-input>
    </b-input-group>
    <div class="invalid-error" v-if="password && password_confirm && !$v.password_confirm.sameAsPassword">
      <span>Пароли не соответствуют друг другу.</span>
    </div>
  </b-form-group>
</template>

<script>
// Validators
import { required, minLength, sameAs } from "vuelidate/lib/validators";

// Utils
import passwordComplexity from "../../customValidators/passwordComplexity";

export default {
  name: "Password",
  data() {
    return {
      password: "",
      password_confirm: ""
    };
  },
  methods: {
    setPassword() {
      this.$emit("onValueChange", "password", this.password);
    },
    setConfirmedPassword() {
      this.$emit("onValueChange", "password_confirm", this.password_confirm);
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      passwordComplexity
    },
    password_confirm: {
      sameAsPassword: sameAs("password")
    }
  }
};
</script>

<template>
  <section class="text-center">
    <h1>Спасибо за заявку!</h1>
    <h4>
      После того, как она будет рассмотрена,
      <br />Вам придет на почту
      <b>
        <u>{{ email }}</u>
      </b>
      письмо с дальнейшими инструкциями
    </h4>
    <h3>
      Вы можете
      <a href="https://stalk.net.ua">Войти в Систему</a> за указанными Вами
      данными (email и пароль)
    </h3>
  </section>
</template>

<script>
export default {
  name: "RegistrationSuccessful",
  props: {
    email: String
  }
};
</script>

<style scoped>
section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 16px;
}

h1 {
  font-size: 3em;
}

h4 {
  font-size: 2em;
}
</style>

<template>
  <b-form-group label="Контактный телефон*" :inline="true">
    <b-input-group>
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa fa-phone" />
        </span>
      </div>
      <masked-input
        type="tel"
        name="phone"
        :class="{ 'is-invalid': $v.phone.$error && isTouched.phone }"
        placeholder="+38 (999) 999-9999"
        class="form-control"
        :mask="[
          '+',
          '3',
          '8',
          ' ',
          '(',
          /[0-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]"
        :guide="true"
        placeholderChar="#"
        v-model.trim="$v.phone.$model"
        v-on:input="setTelephoneNumber()"
        autocomplete="off"
      />
    </b-input-group>
    <small class="form-text text-muted">напр. +38 (999) 999-9999</small>
    <div class="invalid-error" v-if="$v.phone.$error && isTouched.phone">
      <span v-if="!$v.phone.required">Это обязательное для заполнения поле.</span>
    </div>
  </b-form-group>
</template>

<script>
import MaskedInput from "vue-text-mask";
// Validations
import { required } from "vuelidate/lib/validators";

export default {
  name: "PhoneNumber",
  components: {
    MaskedInput
  },
  data() {
    return {
      phone: "",
      isTouched: {
        //need for fix vuelidate and vue-pattern-input incompatibility bug
        phone: false
      }
    };
  },
  methods: {
    setTelephoneNumber() {
      this.$emit("onValueChange", "phone", this.phone);
    }
  },
  validations: {
    phone: {
      required
    }
  }
};
</script>

<template>
  <div class="registration-page">
    <Spinner v-if="isPageLoading"/>
    <RegistrationForbidden
      v-if="!isPageLoading && !event.IsRegistrationOpened"
    />
    <RegistrationSuccessful
      v-if="!isPageLoading && isRegistered"
      v-bind:email="reg.email"
    />
    <b-form
      v-if="canShowRegistrationForm()"
      class="p-4 register-form"
      v-on:submit.prevent
    >
      <h2 class="text-center">
        Регистрация на игру
        <br/>
        <u>{{ event.Title }}</u>
      </h2>
      <p class="text-muted text-center">Внимательно заполните все поля</p>
      <Email
        v-on:onValueChange="onValueChange"
        v-bind:MembersInfo="event.MembersInfo"
        :goToLogin="goToLogin"
      />
      <FullName @onValueChange="onValueChange"/>
      <Password v-on:onValueChange="onValueChange"/>
      <PhoneNumber @onValueChange="onValueChange"/>
      <BirthdayDate v-on:onValueChange="onValueChange"/>
      <City v-on:onValueChange="onValueChange"/>
      <Team v-on:onValueChange="onValueChange"/>
      <b-form-group label="Игровое имя*" :inline="true">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text>
              <i class="icon-user"></i>
            </b-input-group-text>
          </b-input-group-prepend>
          <pattern-input
            class="form-control"
            :class="{
              'is-invalid': $v.reg.callsign.$error && isTouched.callsign
            }"
            placeholder="Позывной"
            :regExp="validation.cyrilic.regExp"
            :replacement="validation.cyrilic.replacement"
            maxlength="35"
            v-model.trim="$v.reg.callsign.$model"
          ></pattern-input>
        </b-input-group>
        <small class="form-text text-muted">Только русские буквы!</small>
        <span v-if="!$v.reg.callsign.$error || !isTouched.callsign">&nbsp;</span>
        <div
          class="invalid-error"
          v-if="$v.reg.callsign.$error && isTouched.callsign"
        >
          <span v-if="!$v.reg.callsign.required">Это обязательное для заполнения поле.</span>
          <span v-if="!$v.reg.callsign.minLength">
            Позывной должен состоять минимум из
            {{ $v.reg.callsign.$params.minLength.min }} букв.
          </span>
          <span v-if="!$v.reg.callsign.isUnique">Такой позывной уже занят.</span>
        </div>
      </b-form-group>

      <b-form-group label="Фото на паспорт*" :inline="true">
        <b-input-group-prepend>
          <img
            v-if="avatar"
            v-bind:src="avatar"
            width="100"
            height="133"
            @click="toggleCropShow"
          />
          <b-button
            v-if="!avatar"
            class="ml-2"
            variant="primary"
            style="z-index: 0"
            @click="toggleCropShow"
          >Загрузить
          </b-button
          >
          <crop-upload
            field="img"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="cropShow"
            :width="300"
            :height="400"
            :noCircle="true"
            langType="ru"
            :url="baseURL + 'images/uploadAvatar'"
            :params="cropParams"
            img-format="png"
          ></crop-upload>
        </b-input-group-prepend>
        <small class="form-text text-muted">
          Необходимо выбрать и аккуратно обрезать фото, так как оно будет
          размещено на Вашем игровом паспорте.
        </small>
      </b-form-group>
      <Fractions
        v-on:onValueChange="onValueChange"
        v-bind:fractions="fractions"
      />
      <Suit v-on:onValueChange="onValueChange" v-bind:suits="suits"/>
      <b-form-group label="Квента персонажа*" :inline="true">
        <textarea
          v-model.trim="$v.reg.legend.$model"
          class="form-control"
          id="legend"
          name="legend"
          style="min-height: 56px"
          rows="5"
          placeholder="Опишите историю вашего персонажа"
        ></textarea>
        <small class="form-text text-muted">Без квенты заявки не принимаются. Максимальная длина - 1800 символов.</small>
        <div class="invalid-error" v-if="$v.reg.legend.$error">
          <span v-if="!$v.reg.legend.required">Это обязательное для заполнения поле.</span>
          <span v-if="!$v.reg.legend.minLength">
            Квента должена состоять минимум из
            {{ $v.reg.legend.$params.minLength.min }} символов.
          </span>
          <span v-if="!$v.reg.legend.maxLength">
            Квента должена состоять максимум из
            {{ $v.reg.legend.$params.maxLength.max }} символов. Имейте совесть,
            уменьшите немного размер.
          </span>
        </div>
      </b-form-group>

<!--
      <b-form-group
        label="Наличие ПДА 'Луч'"
        label-for="pda"
        :label-cols="3"
        :inline="true"
      >
        <b-input-group-prepend>
          <b-form-radio-group id="pda" name="pda">
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="pdaOwn"
                name="pdaOwn"
                class="custom-control-input"
                :value="pdaVariants[0]"
                v-model="reg.pda"
                :disabled="!IsPdaRentEnabled"
              />
              <label class="custom-control-label" for="pdaOwn">Свой</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="pdaRent"
                name="pdaRent"
                class="custom-control-input"
                :value="pdaVariants[1]"
                v-model="reg.pda"
                :disabled="!IsPdaRentEnabled"
              />
              <label class="custom-control-label" for="pdaRent">Прокат</label>
            </div>
            &lt;!&ndash; <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="pdaNone"
                name="pdaNone"
                class="custom-control-input"
                :value="pdaVariants[3]"
                v-model="reg.pda"
                :disabled="!IsPdaRentEnabled"
              />
              <label class="custom-control-label" for="pdaNone">Нет необходимости</label>
            </div> Temporary commented because of business reasons&ndash;&gt;
          </b-form-radio-group>
        </b-input-group-prepend>
        <small class="form-text text-muted" v-if="!IsPdaRentEnabled"
          >К сожалению, лимит орендных ПДА исчерпан.</small
        >
        <small class="form-text text-muted" v-if="IsPdaRentEnabled">
          На игре будет возможность взять на прокат сей девайс за 10$, НО в этом
          случае ДО-ВНОСИТСЯ залог, являющийся полной "прокатной" стоимостью ПДА
          "ЛУЧ".* *То есть, если вы желаете взять на прокат ПДА "ЛУЧ" - вам
          необходимо будет отдать человеку, ответственному за прокат 30$
          (прокатная стоимость) из которых 20$ вернутся к вам после окончания
          игры.
          <a target="_blank" href="https://youtu.be/yQOWRFQ9Y1M">Подробнее</a>
        </small>
      </b-form-group>
-->

      <b-form-group label="Противопоказания" :inline="true">
        <textarea
          v-model.trim="$v.reg.contraindications.$model"
          class="form-control"
          id="contraindications"
          name="contraindications"
          style="min-height: 56px"
          rows="2"
          placeholder="Укажите Ваши медицинские или др. противопоказания (если есть)"
        ></textarea>
      </b-form-group>

      <b-form-group label="Несколько фото в экипировке*" :inline="true">
        <b-input-group-prepend>
          <div
            class="images-upload"
            style="display: flex; justify-content: center;"
          >
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              @data-change="dataChange"
              @size-overflowed="tooBigImageError"
              :data-images="photos"
              dragText="Перетащите фото"
              browseText="или нажмите сюда"
              popupText="загрузите изображение"
              dropText="Отпустите здесь"
              accept="image/jpeg, image/png, image/bmp, image/jpg"
              :show-primary="false"
              :multiple="true"
              :max-image="4"
              :max-size="1000000"
            ></vue-upload-multiple-image>
          </div>
        </b-input-group-prepend>

        <small class="form-text text-muted">
          Прикрепляйте СВОИ фото для прохождения десс-кода. Прохождение дресс-кода
          обязательно! Каждое фото должно быть размером
          <b>до 1МБ</b>.
        </small>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col cols="3" class="mt-1"><label>Соглашение</label></b-col>
          <b-col cols="9">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="spec"
                v-model="checks.spec"
              />
              <label
                class="custom-control-label form-text"
                for="spec"
                style="word-wrap: break-word; user-select: none;"
              >
                Я даю согласие и обязуюсь выполнять
                <a href="files/spec.vector.v4.pdf" target="_blank">правила</a> проведения
                мероприятия
              </label>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="de-jure"
                v-model="checks.deJure"
              />
              <label
                class="custom-control-label form-text"
                for="de-jure"
                style="word-wrap: break-word; user-select: none;"
              >
                Я даю согласие по своей воле и в своем интересе на хранение,
                обработку и использование предоставленных мною персональных данных
              </label>
            </div>
          </b-col>
        </b-row>
      </b-form-group>

      <b-button
        variant="success"
        block
        @click="submitRegistration()"
        :disabled="
      $v.$invalid ||
      !checks.spec ||
      !checks.deJure ||
      reg.photos_ids.length === 0
      "
      >Зарегистрироваться
      </b-button>
    </b-form>
  </div>
</template>

<script>
  // Components
  import Spinner from "../../components/common/Spinner";
  import RegistrationForbidden from "./RegistrationForbidden";
  import RegistrationSuccessful from "./RegistratonSuccessful";
  import Email from "./Email";
  import FullName from "./FullName";
  import Password from "./Password";
  import PhoneNumber from "./PhoneNumber";
  import BirthdayDate from "./BirthdayDate";
  import City from "./City";
  import Team from "./Team";
  import Fractions from "./Fractions";
  import Suit from "./Suit";
  import MaskedInput from "vue-text-mask";
  import Multiselect from "vue-multiselect";
  import vSelect from "vue-select";
  import PatternInput from "vue-pattern-input";
  import VueCoreImageUpload from "vue-core-image-upload";
  import CropUpload from "vue-image-crop-upload";
  import VueGallery from "vue-gallery";
  import VueUploadMultipleImage from "vue-upload-multiple-image";
  // Validations
  import {
    required,
    minLength,
    maxLength,
    integer
  } from "vuelidate/lib/validators";
  // Utils
  import debounce from "debounce-async";
  import API from "../../api/stalk.net";
  import {cyrilicRegExp} from "../../shared/utils";

  const PdaOwn = "own";
  const PdaRent = "rent";
  const PdaPurchase = "purchase";
  const PdaNone = "none";

  const ShotLightOwn = "own";
  const ShotLightPurchase = "purchase";
  const ShotLightNone = "none";

  export default {
    name: "Register",
    components: {
      MaskedInput,
      Multiselect,
      vSelect,
      PatternInput,
      VueCoreImageUpload,
      "crop-upload": CropUpload,
      gallery: VueGallery,
      VueUploadMultipleImage,
      Spinner,
      RegistrationForbidden,
      RegistrationSuccessful,
      Email,
      FullName,
      Password,
      PhoneNumber,
      BirthdayDate,
      City,
      Team,
      Fractions,
      Suit
    },
    data() {
      return {
        checks: {
          deJure: false,
          spec: false
        },
        event: {
          IsRegistrationOpened: true
        },
        // IsPdaRentEnabled: true,
        reg: {
          email: "",
          password: "",
          password_confirm: "",
          phone: "",
          first_name: "",
          last_name: "",
          father_name: "",
          callsign: "",
          born_at: "",
          legend: "",
          contraindications: "",
          leader_candidate: "",
          fraction_id: null,
          suit_id: null,
          city_id: null,
          command: "Внеком",
          pda: PdaOwn,
          shot_light: ShotLightOwn,
          passport_photo_id: 0,
          photos_ids: []
        },
        baseURL: API.baseURL,
        isPageLoading: false,
        isRegistered: false,
        registerCode: "",
        cropShow: false,
        cropParams: {
          token: "",
          name: "avatar"
        },
        avatar: null,
        photos: [],
        photosIndex: null,
        pdaVariants: [PdaOwn, PdaRent, PdaPurchase, PdaNone],
        shotLightVariants: [ShotLightOwn, ShotLightPurchase, ShotLightNone],
        fractions: [],
        suits: [],
        cities: [],
        isCityLoading: false,
        commands: [],
        isCommandLoading: false,
        selectedFraction: {},
        selectedSuit: {},
        selectedCity: {},
        isLeaderCandidate: false,
        isTouched: {
          //need for fix vuelidate and vue-pattern-input incompatibility bug
          phone: false,
          callsign: false
        },
        validation: {
          cyrilic: {
            regExp: cyrilicRegExp,
            replacement: ""
          }
        },
        prevSearchValue: ""
      };
    },
    validations: {
      reg: {
        callsign: {
          required,
          minLength: minLength(2),
          async isUnique(value) {
            if (value === "" || value.length < 2) return true;
            let resp = debounce(API.public.isCallsignUnique, 500);
            let v = await resp(value);
            return Boolean(v.data);
          }
        },
        legend: {
          required,
          minLength: minLength(25),
          maxLength: maxLength(1800)
        },
        contraindications: {},
        leader_candidate: {},
        fraction_id: {
          required,
          integer
        },
        suit_id: {
          required,
          integer
        },
        city_id: {
          required,
          integer
        },
        passport_photo_id: {
          required,
          integer
        }
      }
    },
    methods: {
      onValueChange(property, value) {
        this.reg[property] = value;
      },
      loadData() {
        this.isPageLoading = true;
        API.public
          .findActiveRegEvent()
          .then(resp => {
            this.event = resp.data;
            if (!this.event.IsRegistrationOpened) {
              return Promise.resolve({data: []});
            }
            return API.public.loadFractions();
          })
          .catch(e => {
            if (e && e.response && e.response.data) {
              if (e.response.data.error === "ERR_NOT_FOUND") {
                this.event.IsRegistrationOpened = false;
                return Promise.reject(null); //do not fill error if no opened event
              }
              return Promise.reject(e);
            }
          })
          .then(resp => {
            this.fractions = resp.data;
            if (!this.event.IsRegistrationOpened) {
              return Promise.resolve({data: []});
            }
            return API.public.loadSuits();
          })
          .then(resp => {
            this.suits = resp.data;
          })
          .then(() => {
            this.isPageLoading = false;
            this.$v.$reset();
          })
          .catch(e => {
            if (e) {
              console.error(e);
              this.showRegError({message: e});
            }
            this.isPageLoading = false;
          });
      },
      submitRegistration() {
        let self = this;
        self.$v.$touch();
        if (self.$v.$invalid) {
          return;
        }
        self.isPageLoading = true;
        // console.log('reg res', self.reg);
        // if (!self.IsPdaRentEnabled) {
        //   self.reg.pda = PdaOwn;
        // }
        API.public
          .createMember(self.reg)
          .then(resp => {
            self.registerCode = resp.data;
            self.isRegistered = true;
            self.isPageLoading = false;
          })
          .catch(e => {
            console.error(e);
            self.isPageLoading = false;
          });
      },
      capitalize(value) {
        if (!value) return "";
        value = value.toString();
        return value.charAt(0).toUpperCase() + value.slice(1);
      },

      customLabel({title, desc}) {
        return `${title} – ${desc}`;
      },
      searchQuery(value) {
        //this.showLoadingSpinner = true
        if (this.prevSearchValue !== "" && value !== this.reg.command) {
          let index = this.commands.indexOf(this.prevSearchValue);
          if (index !== -1) this.commands.splice(index, 1);
        }
        this.prevSearchValue = value;
        this.commands.push(value);
      },

      tooBigImageError(size) {
        console.log(size);
        this.showRegError({message: "Изображение превышает 1МБ размером"});
      },
      uploadImageSuccess(formData) {
        // Upload image api
        API.public
          .uploadImages(formData)
          .then(response => {
            // this.reg.photos_ids = [];
            // this.photos = [];
            let res = response.data;
            if (res) {
              if (res.error && res.error !== "") {
                let e = res.error + " - " + res.description;
                console.error(e);
                self.showRegError({message: e});
                return;
              }
              if (res.length === 0) {
                let e = "There is no images in response: " + res;
                console.error(e);
                self.showRegError({message: e});
                return;
              }
              res.forEach(img => {
                if (img.img_id && img.img_id > 0) {
                  this.reg.photos_ids.push(img.img_id);
                }
                // if (img.img_src && img.img_src !== "") {
                //   this.photos.push(img.img_src)
                // }
              });
            }
          })
          .catch(e => {
            console.error(e);
            this.showRegError({message: e});
            this.isPageLoading = false;
          });
      },
      beforeRemove(index, done) {
        done();
      },
      canShowRegistrationForm() {
        return (
          this.event.IsRegistrationOpened &&
          !this.isPageLoading &&
          !this.isRegistered
        );
      },
      editImage() {
        //console.log('edit data', formData, index, fileList)
      },
      dataChange() {
        //console.log(data)
      },
      toggleCropShow() {
        this.cropShow = !this.cropShow;
      },
      cropSuccess(imgDataUrl) {
        this.avatar = imgDataUrl;
      },
      cropUploadSuccess(jsonData) {
        this.reg.passport_photo_id = jsonData.img_id;
      },
      cropUploadFail(status, field) {
        let e = "upload fail - " + status + ", field: " + field;
        console.error(e);
        this.showRegError({message: e});
      },
      goToLogin() {
        this.$router.replace("login");
      }
    },
    beforeMount() {
      this.loadData();
    },
    computed: {
      first_name() {
        return this.reg.first_name;
      },
      last_name() {
        return this.reg.last_name;
      },
      father_name() {
        return this.reg.father_name;
      },
      callsign() {
        return this.reg.callsign;
      }
    },
    watch: {
      first_name(newValue) {
        this.isTouched.first_name = true;
        this.reg.first_name = this.capitalize(newValue);
      },
      last_name(newValue) {
        this.isTouched.last_name = true;
        this.reg.last_name = this.capitalize(newValue);
      },
      father_name(newValue) {
        this.reg.father_name = this.capitalize(newValue);
      },
      callsign(newValue) {
        this.isTouched.callsign = true;
        this.reg.callsign = this.capitalize(newValue);
      },
      phone() {
        this.isTouched.phone = true;
      },
      selectedCommand(newValue) {
        this.reg.command_id = newValue.id;
      },
      isLeaderCandidate(newValue) {
        if (newValue === false) {
          this.reg.leader_candidate = "";
        }
      }
    },
    notifications: {
      showRegError: {
        title: "Ошибка регистрации",
        type: "error"
      }
    }
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style >
  .registration-page {
    background: url("../../../public/img/back/reg.jpg") center no-repeat #000;
    min-height: 100vh;
    padding-top: 16px;
  }

  .register-form {
    background-color: RGBA(58, 65, 73, 0.9);
    max-width: 600px;
    margin: 0 auto;
    border-radius: 6px;
  }

  h2 u {
    font-size: 36px;
  }

  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-image: none;
  }

  .multiselect__tags, /* select design fixes */
  .multiselect__input,
  .multiselect__input:placeholder-shown,
  .multiselect__tags-wrap,
  .multiselect__single,
  .option__img,
  .multiselect__option,
  .multiselect__option--highlight,
  .multiselect__element,
  .multiselect__content {
    background-color: #515b65;
    color: #e4e7ea;
    border: 0 solid;
    white-space: normal;
  }

  .multiselect__input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e4e7ea;
  }

  .multiselect__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #e4e7ea;
  }

  .multiselect__input:-ms-input-placeholder {
    /* IE 10+ */
    color: #e4e7ea;
  }

  .multiselect__input:-moz-placeholder {
    /* Firefox 18- */
    color: #e4e7ea;
  }

  .multiselect__tags,
  .multiselect,
  .multiselect__option {
    min-height: 33px !important;
  }

  .select-wrapper {
    height: 33px;
  }

  .multiselect .multiselect__tags {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: 1px solid #23282c !important;
    border-left: none !important;
  }

  .input-group-text {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .multiselect__content-wrapper {
    background: none !important;
    border: none !important;
    border: 1px solid #23282c !important;
    border-top: 0 !important;
  }

  .multiselect__spinner {
    background-color: transparent !important;
  }

  .multiselect__single,
  .multiselect__input {
    margin-bottom: 0 !important;
  }

  .multiselect__option {
    padding: 8px !important;
  }

  .multiselect__tags {
    padding-top: 5px !important;
  }

  .multiselect__select {
    height: 100%;
    padding: 0;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background-color: #38404a;
    color: #e4e7ea;
    border: 0px solid;
  }

  /* select design fixes */

  .avatar-img {
    margin-left: 20px;
  }

  .invalid-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
  }

  .images-upload {
    text-align: center;
    color: #ffffff;
    background-color: inherit;
    margin-top: 10px;
  }

  .image-container,
  .image-primary {
    background-color: inherit !important;
    border: 1px solid inherit;
  }

  .image-icon-delete,
  .image-icon-edit,
  .image-icon-info,
  .add-image-svg {
    fill: #ffffff !important;
  }

  .vue-image-crop-upload .vicp-wrap {
    width: 80vw;
    height: 200px;
    padding: 10px;
    overflow-x: scroll;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area {
    padding: 35px 0;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-hint {
    padding: 10px;
    white-space: normal;
    line-height: 1.1;
  }

  @media (max-width: 768px) {
    .registration-page {
      padding-top: 0;
      min-height: 100vh;
    }

    .register-form {
      width: 100vw;
      border-radius: 0;
    }
  }

  @media (min-width: 768px) {
    .vue-image-crop-upload .vicp-wrap {
      width: 600px;
      height: 330px;
      padding: 25px;
    }

  }

  @media (min-width: 769px) and (max-width: 1220px) {
    form {
      width: 60vw;
    }
  }
</style>

<template>
  <b-form-group label="Дата рождения*" :inline="true">
    <b-input-group>
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
      <masked-input
        type="text"
        v-on:input="setBirthdayDate()"
        :class="{ 'is-invalid': $v.born_at.$error }"
        name="date"
        class="form-control"
        v-model.trim="$v.born_at.$model"
        :mask="[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]"
        :guide="true"
        placeholderChar="_"
        :showMask="true"
        :keepCharPositions="true"
        :pipe="autoCorrectedDatePipe()"
      />
    </b-input-group>
    <small class="form-text text-muted">напр. 26.04.1986</small>
    <div class="invalid-error" v-if="$v.born_at.$error">
      <span v-if="!$v.born_at.required"
        >Это обязательное для заполнения поле.</span
      >
    </div>
  </b-form-group>
</template>

<script>
import MaskedInput from "vue-text-mask";
// Validations
import { required } from "vuelidate/lib/validators";
// Utils
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

export default {
  name: "BirthdayDate",
  components: {
    MaskedInput
  },
  data() {
    return {
      born_at: ""
    };
  },
  methods: {
    setBirthdayDate() {
      this.$emit("onValueChange", "born_at", this.born_at);
    },
    autoCorrectedDatePipe() {
      return createAutoCorrectedDatePipe("dd/mm/yyyy");
    }
  },
  validations: {
    born_at: {
      required
    }
  }
};
</script>

<template>
  <b-form-group label="Команда*" :inline="true">
    <b-input-group>
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-people"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input
        type="text"
        class="form-control"
        placeholder="Введите название своей команды, если не нашли в списке"
        v-model.trim="$v.selectedCommand.$model"
        v-on:input="inputChangeHandler"
        :class="{ 'is-invalid': $v.selectedCommand.$error }"
      />
    </b-input-group>
    <ul
      class="form-control dropdown list-unstyled"
      v-if="isDropdownOpened && commandsAvailable.length"
    >
      <li
        v-for="command in commandsAvailable"
        :key="command.Title"
        @click="setCommandFromDropdownList(command.Title)"
      >
        {{ command.Title }}
      </li>
    </ul>
    <small class="form-text text-muted">
      Укажите свою страйкбольную комманду или Внеком. Вы можете поискать и
      выбрать из уже существующих или ввести новую.
    </small>
  </b-form-group>
</template>

<script>
import API from "../../api/stalk.net";
// Validators
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "Team",
  data() {
    return {
      selectedCommand: "Внеком",
      commandsAvailable: [],
      isDropdownOpened: false
    };
  },
  methods: {
    asyncFindCommand(query) {
      API.public
        .findCommand(query)
        .then(response => {
          this.commandsAvailable = response.data;
          // this.isCommandsLoading = false;
        })
        .catch(e => {
          console.error(e);
          // this.isCommandsLoading = false;
        });
    },
    setCommand(value) {
      this.selectedCommand = value;
      this.$emit("onValueChange", "command", this.selectedCommand);
    },
    inputChangeHandler() {
      this.asyncFindCommand(this.selectedCommand);
      this.setCommand(this.selectedCommand);
      this.isDropdownOpened = this.commandsAvailable.length > 0;
    },
    setCommandFromDropdownList(value) {
      this.setCommand(value);
      this.isDropdownOpened = false;
    }
  },
  validations: {
    selectedCommand: {
      required,
      minLength: minLength(2)
    }
  }
};
</script>

<template>
  <b-form-group label="Фамилия*, Имя*, Отчество" :inline="true">
    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-user"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <pattern-input
        class="form-control"
        placeholder="Фамилия"
        :class="{
          'is-invalid': $v.last_name.$error && isTouched.last_name
        }"
        :regExp="validation.cyrilic.regExp"
        :replacement="validation.cyrilic.replacement"
        maxlength="35"
        v-model.trim="$v.last_name.$model"
        @input="setLastName()"
      ></pattern-input>
    </b-input-group>
    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-user"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <pattern-input
        class="form-control"
        placeholder="Имя"
        :class="{
          'is-invalid': $v.first_name.$error && isTouched.first_name
        }"
        :regExp="validation.cyrilic.regExp"
        :replacement="validation.cyrilic.replacement"
        maxlength="35"
        v-model.trim="$v.first_name.$model"
        @input="setFirstName()"
      ></pattern-input>
    </b-input-group>
    <b-input-group class="mb-3">
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-user"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <pattern-input
        class="form-control"
        placeholder="Отчество"
        :regExp="validation.cyrilic.regExp"
        :replacement="validation.cyrilic.replacement"
        maxlength="35"
        @input="setFatherName()"
        v-model.trim="$v.father_name.$model"
      ></pattern-input>
    </b-input-group>
    <small class="form-text text-muted">Только русские буквы!</small>
    <div
      class="invalid-error"
      v-if="$v.first_name.$error && isTouched.first_name"
    >
      <span v-if="!$v.first_name.required">Имя обязательное для заполнения поле.</span>
      <span v-if="!$v.first_name.minLength">
        Имя должно состоять минимум из {{ $v.first_name.$params.minLength.min }} букв.
      </span>
    </div>
    <div
      class="invalid-error"
      v-if="$v.last_name.$error && isTouched.last_name"
    >
      <span v-if="!$v.last_name.required">Фамилия обязательное для заполнения поле.</span>
      <span v-if="!$v.last_name.minLength">
        Фамилия должна состоять минимум из
        {{ $v.last_name.$params.minLength.min }} букв.</span>
    </div>
  </b-form-group>
</template>

<script>
import PatternInput from "vue-pattern-input";
// Validators
import { required, minLength } from "vuelidate/lib/validators";
// Utils
import { cyrilicRegExp } from "../../shared/utils";

export default {
  name: "FullName",
  components: {
    PatternInput
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      father_name: "",
      validation: {
        cyrilic: {
          regExp: cyrilicRegExp,
          replacement: ""
        }
      },
      isTouched: {
        //need for fix vuelidate and vue-pattern-input incompatibility bug
        first_name: false,
        last_name: false
      }
    };
  },
  methods: {
    setFirstName() {
      this.$emit("onValueChange", "first_name", this.first_name);
    },
    setLastName() {
      this.$emit("onValueChange", "last_name", this.last_name);
    },
    setFatherName() {
      this.$emit("onValueChange", "father_name", this.father_name);
    }
  },
  validations: {
    first_name: {
      required,
      minLength: minLength(2)
    },
    last_name: {
      required,
      minLength: minLength(2)
    },
    father_name: {}
  }
};
</script>

<template>
  <b-form-group label="Город*" :inline="true">
    <div class="d-flex select-wrapper">
      <b-input-group-text>
        <i class="cui-location-pin"></i>
      </b-input-group-text>
      <multiselect
        v-model="selectedCity"
        :options="cities"
        placeholder=""
        :showNoResults="true"
        :searchable="true"
        :loading="isCityLoading"
        :clear-on-select="false"
        :options-limit="50"
        @search-change="asyncFindCity"
        :showLabels="false"
        label="name"
        return="id"
        track-by="id"
      >
        <template v-slot:noOptions>Давайте начнем искать...</template>
        <template v-slot:noResult>Пока не найдено :( Давай продолжим</template>
      </multiselect>
    </div>
    <small class="form-text text-muted">Город, из которого будете добираться</small>
  </b-form-group>
</template>

<script>
import Multiselect from "vue-multiselect";
import API from "../../api/stalk.net";

export default {
  name: "City",
  components: { Multiselect },
  data() {
    return {
      cities: [],
      isCityLoading: false,
      selectedCity: {}
    };
  },
  methods: {
    asyncFindCity(query) {
      let self = this;
      self.isCityLoading = true;
      API.public
        .findCity(query)
        .then(response => {
          self.cities = response.data;
          self.isCityLoading = false;
        })
        .catch(e => {
          console.error(e);
          self.showRegError({ message: e });
          self.isCityLoading = false;
        });
    }
  },
  watch: {
    selectedCity(newValue) {
      this.$emit("onValueChange", "city_id", newValue.id);
    }
  }
};
</script>

<template>
  <b-form-group label="E-mail*" :label-cols="2" :inline="true">
    <b-input-group>
      <b-input-group-prepend>
        <b-input-group-text>@</b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        type="text"
        class="form-control"
        placeholder="Email"
        v-model="email"
        @blur="handleInput"
        :class="{ 'is-invalid': $v.email.$error }"
        autocomplete="email"
      ></b-form-input>
    </b-input-group>
    <small class="form-text text-muted">напр. w.degtyarev@gmail.com</small>
    <span v-if="!$v.email.$error">&nbsp;</span>
    <div class="invalid-error" v-if="$v.email.$error">
      <span v-if="!$v.email.required">Это обязательное для заполнения поле.</span>
      <span v-if="!$v.email.email">Веденное значение некорректно.</span>
      <span v-if="!$v.email.fineFormat">Неверный формат адреса электронной почты.</span>
      <span v-if="!$v.email.isUnique">
        Этот email уже кем-то занят.
       <span v-if="MembersInfo.Total > 0">
        Попробуйте
        <span
          class="btn-link"
          @click="goToLogin"
          role="button">
          войти
        </span>
        в систему.
       </span>
      </span>
    </div>
  </b-form-group>
</template>

<script>
// Validators
import { required, email } from "vuelidate/lib/validators";
// Utils
import debounce from "debounce-async";
import API from "../../api/stalk.net";

export default {
  name: "Email",
  props: {
    // MembersInfo: Object,
    goToLogin: Function,
    onEmailChange: Function
  },
  data() {
    return {
      email: ""
    };
  },
  methods: {
    setEmail() {
      this.$emit("onValueChange", "email", this.email);
    },
    handleInput(){
      this.$v.email.$touch();
      if(!this.$v.email.$error){
         this.setEmail();
      }
    }
  },
  validations: {
    email: {
      required,
      email,
      fineFormat(value) {
        if (value === "") return false;
        if (value.length < 3) return false;
        if (value.indexOf("+") > -1) {
          return false;
        }
        return true;
      },
      async isUnique(value) {
        // don't use with vuelidate, you'll have a infinite loop  https://github.com/vuelidate/vuelidate/issues/350
        if (value === "" || value.length < 3) return true;
        const resp = debounce(API.public.isEmailUnique, 500);
        const v = await resp(value);
        return Boolean(v.data);
      }
    }
  }
};
</script>

<style scoped>
.btn-link {
  cursor: pointer;
}
</style>
